<template>
  <div class="menu">
    <div class="menu-box">
      <el-menu
        :default-active="activeIndex"
        @select="select"
        class="el-menu-demo"
        mode="horizontal"
        router
      >
        <el-menu-item index="/home">网站首页</el-menu-item>
        <el-menu-item index="/work">找工作</el-menu-item>
        <el-menu-item index="/enterprise">找企业</el-menu-item>
        <el-menu-item index="/resumes">招人才</el-menu-item>
        <el-menu-item index="/flexibleEmployment">零活用工</el-menu-item>
        <!-- <el-menu-item index="/sharedEmployment">共享用工</el-menu-item> -->
        <el-menu-item index="/siteJob">现场招聘会</el-menu-item>
        <!-- <el-menu-item index="/networkJob">网络招聘会</el-menu-item> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  methods: {
    select(index) {}
  },
  computed: {
    ...mapGetters(['activeIndex'])
  }
}
</script>

<style lang="scss">
@import '~@/styles/index.scss';
.menu {
  background: $menuBg;
  height: 50px;
  &-box {
    width: $typeArea;
    margin: 0 auto;
    background: $menuBg;
    .el-menu-demo {
      background: $menuBg;
      border: none;
      .el-menu-item,
      .el-submenu {
        border: none !important;
        height: 50px;
        color: #fff;
        line-height: 50px;
        .el-submenu__title {
          line-height: 50px;
          height: 50px;
          color: #fff;
          border: none !important;
          i {
            color: #fff !important;
          }
        }
        &.is-active {
          background-color: $menuSelect;
          color: #fff;
          .el-submenu__title {
            color: #fff;
          }
        }
        &:hover {
          background-color: $menuSelect !important;
          color: #fff !important;
        }
        &:focus {
          background-color: $menuSelect !important;
          color: #fff !important;
        }
        &:focus .el-submenu__title {
          background-color: $menuSelect !important;
          color: #fff !important;
          height: 50px;
          line-height: 50px;
        }
        &:hover .el-submenu__title {
          background-color: $menuSelect !important;
          color: #fff !important;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
}
</style>
