import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/home')
      },
      {
        path: '/work',
        component: () => import('@/views/work')
      },
      {
        path: '/enterprise',
        component: () => import('@/views/enterprise')
      },
      {
        path: '/resumes',
        component: () => import('@/views/resumes')
      },
      {
        path: '/flexibleEmployment',
        component: () => import('@/views/flexibleEmployment')
      },
      {
        path: '/sharedEmployment',
        component: () => import('@/views/sharedEmployment')
      },
      {
        path: '/clicksitejob',
        component: () => import('@/views/siteJob/click/index')
      },
      {
        path: '/sitejob',
        component: () => import('@/views/siteJob')
      },
      {
        path: '/networkJob',
        component: () => import('@/views/networkJob')
      },
      {
        path: '/detail',
        component: () => import('@/views/detail')
      },
      {
        path: '/sc',
        component: () => import('@/views/detail/detailed/sc')
      },
      {
        path: '/information',
        component: () => import('@/views/information')
      },
      {
        path: '/info',
        component: () => import('@/views/info')
      },
      {
        path: '/click',
        component: () => import('@/views/siteJob/click/index')
      },
      {
        path: '/companyinformation',
        component: () => import('@/views/companyinformation')
      },
      {
        path: '/addjob',
        component: () => import('@/views/companyinformation/addjob/index')
      },
      {
        path: '/updatejob',
        component: () => import('@/views/companyinformation/addjob/updatejob')
      },
      {
        path: '/biographical',
        component: () => import('@/views/companyinformation/biographical/index')
      },
      {
        path: '/job',
        component: () => import('@/views/companyinformation/job/index')
      },
      {
        path: '/transcompany',
        component: () => import('@/views/companyinformation/transcompany/index')
      },
      {
        path: '/info_biographical',
        component: () => import('@/views/companyinformation/biographical/info_biographical/index')
      },
      {
        path: '/c1',
        component: () => import('../views/flexibleEmployment/trans')
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/register',
    component: () => import('@/views/register')
  },

  {
    path: '/company',
    component: () => import('@/views/company')
  },
  {
    path: '/dp',
    component: () => import('@/views/dp')
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  store.commit('app/SET_ACTIVE_INDEX', to.path)
  next()
})

export default router
