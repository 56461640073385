<template>
  <div class="navbar">
    <div class="navbar-title">
      <div class="navbar-title-content">
        <router-link to="/login">
          <div v-if="!userinfo">登录</div>
        </router-link>
        <div style="width: 15px"></div>
        <router-link to="/register">
          <div v-if="!userinfo">注册</div>
        </router-link>
        <div v-if="userinfo" @click="trans" style="margin-right: 10px;">个人中心</div>
        <div  @click="trans1" style="margin-right: 10px;">信息发布大屏</div>
        <div v-if="userinfo" @click="logout" style="margin-right: 10px;">退出</div>
        <!-- <div></div> -->
      </div>
    </div>
    <div class="navbar-center">
      <router-link to="/">
        <div class="navbar-center-logo"></div>
      </router-link>
      <el-input placeholder="请输入内容" v-model="value" :class="{ 'input-with-select': true, hover }" @focus="hover = true"
        @blur="hover = false">
        <template slot="prepend">
          <el-select v-model="select" placeholder="请选择">
            <el-option label="找工作" value="1"></el-option>
            <el-option label="找企业" value="2"></el-option>
          </el-select>
          <div class="xian"></div>
        </template>
      </el-input>
      <el-button class="searchBtn" type="primary">搜索</el-button>
    </div>
  </div>
</template>

<script>
import { logout } from '../../api/api'
export default {
  data() {
    return {
      type: '',
      value: '',
      select: '1',
      hover: false,
      userinfo: {},
    }
  },
  created() {
    this.type = JSON.parse(localStorage.getItem('user'))?.type || 0
    this.userinfo = JSON.parse(localStorage.getItem('user'))
    console.log(this.userinfo)
    //每两小时自动退出
    setInterval(() => {
      this.logout()
    }, 1000 * 60 * 60 * 2)
  },
  methods: {
    trans() {
      if (this.type == 1) {
        this.$router.push({ path: '/companyinformation' })
      } else {
        this.$router.push({ path: '/info' })
      }
    },
    trans1() {
      this.$router.push({ path: '/dp' })
    },
    async logout() {
      let userId = localStorage.getItem('userId')
      const res = await logout(userId)

      this.$message.success('退出成功')
      localStorage.clear();
      setTimeout(() => {
        this.$router.push("/home").catch(err => err);;
        location.reload();
      }, 500);
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/index.scss';

.navbar {
  &-title {
    height: 38px;
    background: #f8f8f8;

    &-content {
      height: 100%;
      width: $typeArea;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;

      div {
        cursor: pointer;
        color: #303133;
      }
    }
  }

  &-center {
    padding: 20px 0;
    width: $typeArea;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 2px;

    &-logo {
      width: 285px;
      height: 85px;
      background: url('~@/assets/logo.png') no-repeat;
      background-size: contain;
      margin-right: 12px;
      cursor: pointer;
    }

    .input-with-select {
      border-radius: 0;
      height: 50px;
      border: none;
      display: flex;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.13);
      box-sizing: border-box;
      border: 1px solid transparent;
      transition: border 0.2s linear;
      background-color: #fff;

      &.hover {
        border: 1px solid #1787fb;
      }

      &:hover {
        border: 1px solid #1787fb;
      }

      .el-input-group__prepend {
        border: none;
        border-radius: 0;
        padding: 0;
        width: 130px;
        height: 48px;
        background: #fff;
        color: #000;
        display: flex;
        align-items: center;

        .xian {
          width: 1px;
          height: 26px;
          background: #f2f2f5;
        }

        .el-select {
          margin: 0;
        }

        .el-input__icon {
          color: #000;
        }
      }

      .el-input__inner {
        border: none;
        height: 48px;
        font-size: 16px;
        width: 100%;
      }
    }

    .searchBtn {
      width: 160px;
      height: 50px;
      border-radius: 0;
    }
  }
}</style>
