export default {
  namespaced: true,
  state: {
    activeIndex: sessionStorage.getItem('activeIndex') || '/index'
  },
  mutations: {
    SET_ACTIVE_INDEX(state, activeIndex) {
      state.activeIndex = activeIndex
      sessionStorage.setItem('activeIndex', activeIndex)
    }
  },
  actions: {}
}
