import request from "../../axios";
export const list = (type) => request({ url: `/content/list/${type}` });
export const detail = (id) => request({ url: `/user/detail/${id}` }); //通过ID查企业信息
export const companydetail = () =>
  request({ url: "/company/detail-by-loginId" }); //企业详情
export const companyPage = (params) =>
  request({ url: "/open/company/page" }, params);
// export const login = (params) =>
//   request({
//     url:
//       "/login/user/login?phone=" +
//       params.phone +
//       "&password=" +
//       params.password +
//       "&type=" +
//       params.type,
//   });
export const login = (params) =>
  request({
    url: "/login/user/login",
    method: "GET",
    params: params,
  });
export const test = (params) =>
  request({
    url: "/open/company/page",
    method: "GET",
    params: params,
  });
export const jobPage = (params) =>
  request({
    url: "/open/job/page",
    method: "GET",
    params: params,
  });
// export const logout = (loginId) => request({ url: `/logout/${loginId}`})
export const logout = (params) =>
  request({ url: "/login/logout?loginId=" + params });
export const register = (data) =>
  request({
    url: "/user/register",
    method: "POST",
    data,
  });
export const companySave = (data) =>
  request({
    url: "/company/save",
    method: "POST",
    data,
  });
export const updateCompany = (data) =>
  request({
    url: "/user/update-basic",
    method: "POST",
    data,
  });
export const meetPage = (data) =>
  request({
    url: "/open/meet/page",
    method: "GET",
    data,
  });
export const meetlist = (data) =>
  request({
    url: "/open/meet/list",
    method: "GET",
    data,
  });
export const jobClick = (data) =>
  request({
    url: "/delivery/boss",
    method: "POST",
    data,
  });
export const abilityPage = (params) =>
  request({
    url: "/open/good-user/page",
    method: "GET",
    params,
  });
export const eduSave = (data) =>
  request({
    url: "/user/save-or-update-edus",
    method: "POST",
    data,
  });
export const expSave = (data) =>
  request({
    url: "/user/save-or-update-exp",
    method: "POST",
    data,
  });
export const loadJobList = (params) => request({ url: "/job/page", params });
export const saveJob = (data) =>
  request({ url: "/job/save", data, method: "POST" });
export const updateJob = (data) =>
  request({ url: "/job/update", data, method: "POST" });
export const receive = (id) =>
  request({ url: `/company/company-receive/${id}` });
export const job = (id) => request({ url: `/company/company-job/${id}` });
export const detailcompany = (id) =>
  request({ url: `/open/company/detail/${id}` }); //根据企业ID查看详情
export const dpType = (type) => request({ url: `/open/job/area/${type}` }); //大屏点击
