import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    uesrinfo: localStorage.getItem('userinfo') || '',
    userId: localStorage.getItem('userId') || '',
    phone: localStorage.getItem('phone') || '',
    companyId:localStorage.getItem('companyId') || '',
  },
  getters,
  mutations: {
    SET_USERINFO(state, uesrinfo) {
			state.token = uesrinfo
			localStorage.setItem("userinfo", uesrinfo)
		},
    SET_USERID(state, userId) {
			state.userId = userId
			localStorage.setItem("userId", userId)
		},
    SET_TOKEN(state, token) {
			state.token = token
			localStorage.setItem("token", token)
		},
    SET_PHONE(state, phone) {
			state.phone = phone
			localStorage.setItem("phone", phone)
		},
    SET_USER(state, user) {
			state.user = user
			localStorage.setItem("user", user)
		},
    SET_COMPANYID(state, companyId) {
			state.companyId = companyId
			localStorage.setItem("companyId", companyId)
		},
  },
  actions: {},
  modules: {
    app
  }
})
