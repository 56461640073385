<template>
  <div>
    <navber></navber>
    <Meun></Meun>
    <div class="app-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import navber from './navbar'
import Meun from './menu'
export default {
  components: { navber, Meun }
}
</script>

<style lang="scss">
@import '~@/styles/index.scss';
.app-main {
  width: $typeArea;
  margin: 0 auto;
  padding-bottom: 20px;

}
</style>
